import React, { useEffect, useState } from 'react';

const Dropdown = ({ onSelectionChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Programmes');  

    const options = [
        'Centre Jean-Gol',
        'Programmes',
    ];

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);  // Mettez à jour l'option sélectionnée
    };

    useEffect(() => {
        onSelectionChange(selectedOption);  // Envoyez l'option sélectionnée actuelle
    }, [selectedOption, onSelectionChange]);  // Suivez les changements de selectedOption

    return (
        <div className='relative'>
            <button
                className='bg-white text-black rounded-md focus:outline-none focus:shadow-outline flex items-center'
                style={{ boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.2)' }}
                onClick={toggleDropdown}>
                <svg
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='mx-1 font-bold'>
                    <path
                        d='M20 7C20 9.20914 16.4183 11 12 11C7.58172 11 4 9.20914 4 7C4 4.79086 7.58172 3 12 3C16.4183 3 20 4.79086 20 7Z'
                        stroke='#323232'
                        strokeWidth='2'
                    />
                    <path
                        d='M20 12C20 14.2091 16.4183 16 12 16C7.58172 16 4 14.2091 4 12'
                        stroke='#323232'
                        strokeWidth='2'
                    />
                    <path
                        d='M4 7V17C4 19.2091 7.58172 21 12 21C16.4183 21 20 19.2091 20 17V7'
                        stroke='#323232'
                        strokeWidth='2'
                    />
                </svg>
                <span className='inline-block mr-3 font-semibold'>Database</span>
                <svg
                    width='10'
                    height='10'
                    viewBox='0 0 7 5'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='inline-block ml-4 mr-2'>
                    <path
                        d='M1 1L3.5 4L6 1'
                        stroke='black'
                        strokeWidth='1.7'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </svg>
            </button>

            {isOpen && (
                <div className='absolute mt-1 bg-white border rounded shadow-md z-10 min-w-min'>
                    {options.map((option) => (
                        <label
                            key={option}
                            className='px-2 py-1 flex items-center'>
                            <input
                                type='radio'
                                checked={selectedOption === option}
                                onChange={() => handleOptionChange(option)}
                                className='mr-2 leading-tight'
                            />
                            <span className='text-sm whitespace-nowrap font-semibold'>
                                {option}
                            </span>
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;








