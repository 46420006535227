import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { setChatID } from './chatIDModule';


const Disclaimer = ({ onIDGenerated, show }) => {
    const [showDisclaimer, setShowDisclaimer] = useState(true);
    const [acceptedConditions, setAcceptedConditions] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setShowDisclaimer(show);
        setAcceptedConditions(false);
    }, [show]);

    const onClickcaptcha = (e) => {
    e.preventDefault();
	const chatID = "675217d5-2fd3-4560-9d88-06a620a9fb55";
	setChatID(chatID);
	onIDGenerated(chatID);
    console.log("Le bouton a été cliqué"); // Pour déboguer
    if (acceptedConditions) {
        setShowDisclaimer(false); // Cela devrait fermer le disclaimer
    } else {
        alert('Veuillez accepter les conditions.');
    }
};


    return (
        <div
            className={`${
                showDisclaimer ? 'block' : 'hidden'
            } fixed top-0 left-0 w-full h-full bg-black/50 z-50 flex items-center`}>
            <div className='mx-auto bg-white p-5 rounded-xl w-11/12 sm:w-[502px] text-center shadow-xl '>
                <p className='text-xl font-semibold my-5'>Assistant Interne MR:</p>
                <div className='text-left'>
                    <p className='text-base my-4'>
                    Comment m'utiliser ? <br></br>

                        Sélectionnez le type de données sur lesquelles vous voulez discuter: Centre Jean-Gol ou les programmes des partis politiques belges de 2024.
                        <br></br>
                        Pour discuter avec le Centre Jean-Gol, posez simplement votre question.
                        <br></br>
                        Pour discuter avec un programme spécifique, mentionnez le nom du parti dans votre message et posez votre question.
                        <br></br>
                        Attention : Vous ne pouvez pas poser une question sur deux programmes en simultané. Si vous désirez comparer deux programmes, posez deux questions successives.
                        <br></br>
                        Merci et bonne utilisation !
                    </p>
                </div>
                <div className='flex justify-center items-center my-4'>
                    <label className=' '>
                        <input
                            type='checkbox'
                            checked={acceptedConditions}
                            onChange={() => setAcceptedConditions(!acceptedConditions)}
                            className='w-5 h-5 m-2  rounded-sm accent-blue-500  forced-colors:appearance-auto '
                        />
                    </label>
                    <div className='text-xs text-left'>
                        J'ai compris !
                        <br />
                        {/* <a href='https://www.mr.be/mentions-legales/'
                        className='text-xs text-blue-600 underline'
                        target='_blank'
                        rel='noopener noreferrer'>
                        Conditions générales d’utilisation
                        </a> */}
                    </div>
                </div>
                <button
                    onClick={onClickcaptcha}
                    disabled={isSubmitting} // Désactive le bouton pendant l'appel API
                    className={`${
                        !acceptedConditions || isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                    } py-1 px-8 text-white rounded-lg bg-gradient-to-r from-blue-600 to-blue-300 my-3`}
                    style={{ boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.2)' }}>
                    J'y vais!
                </button>
            </div>
        </div>
    );
};

export default Disclaimer;
