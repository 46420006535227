import React from "react";

const ChatBoxLoader = ({ selectLanguage }) => {
  return (
    <>
      <img
        src="https://ui-chatbot1.s3.eu-north-1.amazonaws.com/loader.gif"
        alt=""
        className=" mx-1 my-2 inline-block w-8 h-8 "
      />
      <span>Je cherche dans mes sources ...</span>
    </>
  );
};

export default ChatBoxLoader;
